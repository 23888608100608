<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";

// import countTo from "vue-count-to";
import DatePicker from "vue2-datepicker";
import Multiselect from "vue-multiselect";
//import { VFrappeChart } from 'vue-frappe-chart';

export default {
  page: {
    title: "",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  components: {
    Layout,
    PageHeader,
    // countTo,
    Multiselect,
    //VFrappeChart,
//    FusionCharts,
    DatePicker
  },
  data() {
    return {
      title: "Dashboard",
      items: [
            {
            text: "CloudKitch",
            },
            {
            text: "",
            active: true,
            },
      ],
     tableData: [
          {name: 'Usage', data: {'2017-01-01': 3, '2017-01-02': 4}},
          {name: 'Revenue', data: {'2017-01-01': 5, '2017-01-02': 3}}
        ],
     
      chartOptionsCorpStats:{},
      daterange:[],//[new Date(),new Date()],
      dashboardData:[],
      foodCourtSeries: [],
      overViewData: [],
      mealPlanGraphArr:[],
      corporateStats:[],
      branches:[],
      cityArr:[],
      city:"",
      branchID:"",
      corpBranchID:"",
      cafeteriaArr:[],
      restBranch:"",
      restBranchID:"",
      corpArr:[],
      corpBranch:"",
      countryArr :[],
      country : "",
      salesGraph:[],
      userGraph:[],
      principleBranchID:"",
      isLoading:0,
      // stats data
        series: [{
                data: [25, 66, 41, 89, 63, 25, 44, 20, 36, 40, 54]
            }],
            chartOptions: {
                fill: {
                    colors: ["#5b73e8"]
                },
                chart: {
                    type: "bar",
                    sparkline: {
                        enabled: true
                    }
                },
                plotOptions: {
                    bar: {
                        columnWidth: "50%"
                    }
                },
                labels: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11],
                xaxis: {
                    crosshairs: {
                        width: 1
                    }
                },
                tooltip: {
                    fixed: {
                        enabled: false
                    },
                    x: {
                        show: false
                    },
                    y: {
                        title: {
                            formatter: function () {
                                return "";
                            }
                        }
                    },
                    marker: {
                        show: false
                    }
                }
            },
            growthChartOptions: {
                fill: {
                    colors: ["#f1b44c"]
                },
                chart: {
                    type: "bar",
                    sparkline: {
                        enabled: true
                    }
                },
                plotOptions: {
                    bar: {
                        columnWidth: "50%"
                    }
                },
                labels: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11],
                xaxis: {
                    crosshairs: {
                        width: 1
                    }
                },
                tooltip: {
                    fixed: {
                        enabled: false
                    },
                    x: {
                        show: false
                    },
                    y: {
                        title: {
                            formatter: function () {
                                return "";
                            }
                        }
                    },
                    marker: {
                        show: false
                    }
                }
            },
            orderseries: [70],
            orderRadial: {
                fill: {
                    colors: ["#34c38f"]
                },
                chart: {
                    sparkline: {
                        enabled: true
                    }
                },
                dataLabels: {
                    enabled: false
                },
                plotOptions: {
                    radialBar: {
                        hollow: {
                            margin: 0,
                            size: "60%"
                        },
                        track: {
                            margin: 0
                        },
                        dataLabels: {
                            show: false
                        }
                    }
                }
            },
            customerseries: [55],
            customerRadial: {
                fill: {
                    colors: ["#5b73e8"]
                },
                chart: {
                    sparkline: {
                        enabled: true
                    }
                },
                dataLabels: {
                    enabled: false
                },
                plotOptions: {
                    radialBar: {
                        hollow: {
                            margin: 0,
                            size: "60%"
                        },
                        track: {
                            margin: 0
                        },
                        dataLabels: {
                            show: false
                        }
                    }
                }
            },
      /// stats data end
   
 
    };
  },
   mounted(){
    this.getPrincipleCountry();
    },
    methods:{
    
    arrowClass(percent){

        if(percent>0){
             return 'mdi mdi-arrow-up-bold me-1';
        }else{
            return 'mdi mdi-arrow-down-bold me-1';
        }
    },
    percentColorClass(percent){
       
        if(percent>0){
             return 'text-success me-1';
        }else{
            return 'text-danger me-1';
        }
    },
    getPrincipleDashboard()
    {
        this.isLoading=1;
        var principleID = (this.$storageData.profile.principleID && this.$storageData.profile.principleID != 0) ? this.$storageData.profile.principleID : 0;
        var principleBranchID = (this.$storageData.profile.principleBranchID && this.$storageData.profile.principleBranchID != 0) ? this.$storageData.profile.principleBranchID : this.principleBranchID != "" ? this.principleBranchID  : 0;
        var corpBranchID = (this.corpBranch != "" && this.corpBranch != null) ? this.corpBranch.corpBranchID : 0;
        
        //alert(principleBranchID);
        this.axios.post(this.$loggedRole+"/principleDashboard",
        {
            'principleID':principleID,
            'principleBranchID':principleBranchID,
            'corpBranchID':corpBranchID,
            'empTypeID':this.$storageData.profile.empTypeID,
            'empID':this.$storageData.profile.pid,
            'daterange':this.daterange,
            // 'restBranchID':this.restBranchID,
            'country' : this.country.country,
            'city' : this.city.city,
            'princBranchData' : this.branches,
        })
        .then((result)=>{
            this.isLoading=0;
            this.dashboardData = result.data.data; 
            this.foodCourtSeries = result.data.data.foodCourtArr.series;
            this.overViewData = result.data.salesOverView;
            // this.salesReportGraph();            
        });
    },
      getPrincipleCountry(){
        this.axios
        .post(this.$loggedRole+"/getPrincipleCountry", {principleID:this.$storageData.profile.principleID,
           isDashboard : 1, // for all dropdown in only dashboards
          }
        )
        .then((response) => {
                this.countryArr = response.data.data;
                // this.country = (this.countryArr) ? this.countryArr[0] : "";
                this.getPrincipleCities();
                
        });
    },
      getPrincipleCities(){
         this.axios
        .post(this.$loggedRole+"/getPrincipleCities", {
              principleID:this.$storageData.profile.principleID,
              'empTypeID':this.$storageData.profile.empTypeID,
              'empID':this.$storageData.profile.principleAdminID,
        //    country: (this.country) ? this.country.country : "",
           isDashboard : 1, // for all dropdown in only dashboards
           }
        )
        .then((response) => {
                this.cityArr = response.data.data;
                // this.city = (this.cityArr) ? this.cityArr[0] : "";
                 this.getPrincipleBranchByPrincipleID();
                
        });
    },
    getPrincipleBranchByPrincipleID(){
         this.axios
        .post(this.$loggedRole+"/getPrincipleBranchByPrincipleID",
          {
              principleID:this.$storageData.profile.principleID,
              city: (this.city) ? this.city.city : "",
              'empTypeID':this.$storageData.profile.empTypeID,
              'empID':this.$storageData.profile.principleAdminID,
              isDashboard : 1, // for all dropdown in only dashboards
          }
        )
        .then((response) => {
        this.branches = response.data.data;
        // this.branchID = this.branches[0];
        // this.principleBranchID = this.branchID.principleBranchID;
        // this.getCorporateCafeteria(this.principleBranchID);
        // this.getPrincipleDashboard();
        // this.getPrincipleCorporateBranches(this.principleBranchID);
        
        });
    },
    getPrincipleCorporateBranches(principleBranchID){
             this.axios
            .post(this.$loggedRole+"/getPrincipleCorporateBranches", {
                    principleBranchID: principleBranchID,
                  
                }
            )
            .then((response) => {
                     this.corpArr = response.data.data;
                    //  this.corpBranch = (this.corpArr) ? this.corpArr[0] : [];
                     this.principleCorporateStatistics();
            });
    },
    principalBranchUsers(){
          var principleID = (this.$storageData.profile.principleID && this.$storageData.profile.principleID != 0) ? this.$storageData.profile.principleID : 0;
          var principleBranchID = (this.$storageData.profile.principleBranchID && this.$storageData.profile.principleBranchID != 0) ? this.$storageData.profile.principleBranchID : this.principleBranchID != "" ? this.principleBranchID  : 0;

             this.axios
            .post(this.$loggedRole+"/principalBranchUsers", {
                principleBranchID: principleBranchID,
                principleID : principleID,
                'empTypeID':this.$storageData.profile.empTypeID,
                'empID':this.$storageData.profile.principleAdminID,
                'country' : this.country.country,
                'city' : this.city.city,
                'princBranchData' : this.branches,
                'daterange':this.daterange,
            })
            .then((response) => {
                     this.userGraph = response.data.data;
            });
    },
    principleCorporateStatistics(){
         this.axios
        .post(this.$loggedRole+"/principleCorporateStatistics", {
                principleBranchID:  this.principleBranchID,
                corpBranchID : this.corpBranch.corpBranchID,
                daterange:this.daterange,
            }
        )
        .then((response) => {
             this.corporateStats = response.data.data.series;
              //this.seriesMeal= response.data.series,
                    this.chartOptionsCorpStats= {
                        dataLabels: {
                                enabled: false
                                },
                                stroke: {
                                curve: "smooth",
                                width: 3
                                },
                                colors: ['#5b73e8', '#f1b44c'],
                                xaxis: {
                                type: "datetime",
                                    categories: response.data.data.labels,
                                },
                                grid: {
                                borderColor: "#f1f1f1"
                                },
                                tooltip: {
                                x: {
                                    format: "dd/MM/yy HH:mm"
                                }
                                }
                    }
        });
    },
    onchangeBranch(){
        this.principleBranchID = this.branchID.principleBranchID;
        this.getPrincipleCorporateBranches(this.principleBranchID);
        // this.salesReportGraph();
        // this.getPrincipleCorporateBranches(this.principleBranchID);
        // this.getCorporateCafeteria(); // passing corporate Branch ID
        // this.getPrincipleDashboard();  
        // this.salesReportGraph();
    },

    salesReportGraph(){
        // console.log(this.corpBranch);
        var principleID = (this.$storageData.profile.principleID && this.$storageData.profile.principleID != 0) ? this.$storageData.profile.principleID : 0;
        var principleBranchID = (this.$storageData.profile.principleBranchID && this.$storageData.profile.principleBranchID != 0) ? this.$storageData.profile.principleBranchID : this.principleBranchID != "" ? this.principleBranchID  : 0;
        var corpBranchID = (this.corpBranch != "" && this.corpBranch != null) ? this.corpBranch.corpBranchID : 0;
        // console.log(this.daterange);
        
        this.axios.post(this.$loggedRole+"/salesReportGraph", {
            'corpBranchID': corpBranchID,
            'principleID' : principleID,
            'principleBranchID' : principleBranchID,
            'restID' : 0,//(this.brandData!="") ? this.brandData.restID : this.$storageData.profile.restID,
            'restBranchID' : 0,//this.restBranchID,
            'empTypeID':this.$storageData.profile.empTypeID,
            'daterange':this.daterange,
            'paymentType' : "",//this.paymentType,
            'orderType' : "",//this.orderType,
            'flag':0,//this.sortFlag.id,
            'empID':this.$storageData.profile.principleAdminID,
            'country' : this.country.country,
            'city' : this.city.city,
            'princBranchData' : this.branches,
        })
        .then((result)=>{             
            this.salesGraph = result.data.data;
        });
    },
    // clearFilter(){
    //     this.principleBranchID = this.branchID.principleBranchID;
    //     this.daterange = [];
    //     this.getPrincipleDashboard();
    // },
    changeRestBranch(){
        this.restBranchID = this.restBranch.restBranchID;
    },

    changeCorpBranch(){
        if(this.daterange.includes(null))
      {
        this.daterange = [];
      }
       this.principleBranchID = this.branchID.principleBranchID;
       this.getPrincipleDashboard();
        this.principalBranchUsers();
        this.salesReportGraph();
        this.getCorporateCafeteria();
        this.getPrincipleCorporateBranches(this.principleBranchID);
    //     this.salesReportGraph();
    //     // this.getPrincipleCorporateBranches(this.principleBranchID);
    //     this.getCorporateCafeteria(); // passing corporate Branch ID
    //     this.getPrincipleDashboard();  
    //    this.principleCorporateStatistics();
    },

    getCorporateCafeteria(){
//   var principleID = (this.$storageData.profile.principleID && this.$storageData.profile.principleID != 0) ? this.$storageData.profile.principleID : 0;
        var principleBranchID = (this.$storageData.profile.principleBranchID && this.$storageData.profile.principleBranchID != 0) ? this.$storageData.profile.principleBranchID : this.principleBranchID != "" ? this.principleBranchID  : 0;
        //   var corpBranchID = (this.corpBranchID != "") ? this.corpBranchID.corpBranchID : 0;
        this.axios.post(this.$loggedRole+"/getPrincipleCafeteria",
            {
                'principleBranchID':principleBranchID
            }
            )
            .then((result)=>{
                this.cafeteriaArr = result.data.data;
                this.restBranch = (this.cafeteriaArr) ? this.cafeteriaArr[0] : '';
                this.restBranchID = this.restBranch.restBranchID;
                //this.principleCorporateStatistics(this.restBranchID);
            });
    },
    clearFilter(){
      this.country ="";
      this.city = "";
      this.branchID = "";
      this.principleBranchID = this.branchID.principleBranchID;
      this.daterange = [];
      this.corpBranch = "";
      this.isLoading=0;
    //   this.getPrincipleDashboard();
    this.getPrincipleDashboard();
    this.getPrincipleCountry();
    this.principalBranchUsers();
    this.salesReportGraph();
    this.getCorporateCafeteria();
    this.getPrincipleCorporateBranches(this.principleBranchID);
    },

    applyFilter(){
        if(this.daterange.includes(null))
      {
        this.daterange = [];
      }
       this.principleBranchID = this.branchID ? this.branchID.principleBranchID : 0;
       this.getPrincipleDashboard();
        // this.getPrincipleCountry();
        this.principalBranchUsers();
        this.salesReportGraph();
        this.getCorporateCafeteria();
        this.getPrincipleCorporateBranches(this.principleBranchID);
        // this.getPrincipleDashboard();
        // this.getPrincipleCorporateBranches(this.principleBranchID);
        // this.getCorporateCafeteria(); // passing corporate Branch ID
    },
    },

    
};
</script>

<template>
  <Layout>
    <PageHeader :items="items" />
  

    <!--FILTER START--->
    <div class="card" style="margin-top:-30px">
      <div class="card-body">
          <div class="inner mb-2 row" >
                <!-- Brand List-->
            <div class="col-md-2" style="width:16%"
            > 
                          <label>Select Country </label>
                              <multiselect v-model="country" :options="countryArr" track-by="country" label="country"
                                @input="getPrincipleCities();" :show-labels="false"></multiselect>
          </div>
        <!-- Brand List END-->
        <!-- Branch List-->
               <div class="col-md-2" style="width:16%"
        >   
                     <label>Select City </label>
                        <multiselect v-model="city" :options="cityArr" track-by="city" label="city"
                          @input="getPrincipleBranchByPrincipleID();" :show-labels="false"></multiselect>
         </div>
<!-- Branch List END-->
<!-- Branch List-->
        <div class="col-md-2" style="width:20%">   
                <label>Select Principal Branch </label>
                <multiselect v-model="branchID" :options="branches" track-by="principleBranchID" label="principleBranchName"
                             :show-labels="false" @input="onchangeBranch()"></multiselect>
                             <!-- @input="onchangeBranch();" -->
      </div>
<!-- Branch List END-->
<!-- Date Range-->
        <div class="col-md-2" style="width:20%">   
                 <label>Date Range </label>
            <date-picker v-model="daterange" format="DD MMM Y" range append-to-body lang="en" confirm ></date-picker>
            <!-- @input="onchangeBranch();" -->
           
          <!-- </div> -->
      </div>
<!-- Branch List END-->
 
<!-- Apply filter -->
                <div class="col-md-2"  style="width:auto; margin-top:20px;">   
                 <button class="btn btn-themeOrange"  v-on:click="applyFilter()"> Apply Filter </button>
                </div>
<!-- Apply filter END-->

<!-- Clear filter -->
                <div class="col-md-2"  style="width:16%;margin-top:20px;">   
                 <button class="btn btn-themeBrown"  v-on:click="clearFilter()"> Clear Filter </button>
                </div>
<!-- Clear filter END-->
        </div>
   </div>
    </div>
    <!--FILTER END------>
            
    <!-- stats start -->
    <div class="row" style="margin: 10px 0 0;">
        <div v-if="isLoading==1" class="text-center text-danger my-2">
            <b-spinner class="align-middle"></b-spinner>
            <strong>Loading Please wait...</strong>
        </div>
        <div class="col-md-6 col-xl-3 overviewCard">
            <div class="card">
                <div class="card-body">
                    <div>
                        <h4 class="cardTitle mb-1 mt-1">
                                {{ (overViewData.sumGrandTotal) ? $func.formatPrice(overViewData.sumGrandTotal) : 0 }}
                        </h4>
                        <p class="text-muted mb-0">Total</p>
                    </div>
                    <div class="row">
                        <p class="text-muted mt-3 mb-0 col-7 cardSubtitle1">
                            <span>
                                {{ (overViewData.totalOrders) ? $func.formatCommaSepNum(overViewData.totalOrders) : 0 }}
                            </span> 
                            Orders
                        </p>
                        <span class="align-center col-5 cardSubtitle2">
                            <p>{{ (overViewData.avgSale) ? $func.formatPrice(overViewData.avgSale) : 0 }}</p> AOV
                        </span>
                    </div>
                </div>
            </div>
        </div>

        <div class="col-md-6 col-xl-3 overviewCard">
            <div class="card">
                <div class="card-body">
                    <div>
                        <h4 class="cardTitle mb-1 mt-1">
                            {{ (overViewData.sumDigitalGrandTotal) ? $func.formatPrice(overViewData.sumDigitalGrandTotal) : 0 }}
                        </h4>
                        <p class="text-muted mb-0">Digital</p>
                    </div>
                    <div class="row">
                        <p class="text-muted mt-3 mb-0 col-7 cardSubtitle1">
                            <span>
                                {{ (overViewData.totalDigitalOrders) ? $func.formatCommaSepNum(overViewData.totalDigitalOrders) : 0 }}
                            </span> 
                            Orders
                        </p>
                        <span class="align-center col-5 cardSubtitle2">
                            <p>
                                {{ (overViewData.digitalAvgSale) ? $func.formatPrice(overViewData.digitalAvgSale) : 0 }}
                            </p>
                            AOV
                        </span>
                    </div>
                </div>
            </div>
        </div>

        <div class="col-md-6 col-xl-3 overviewCard">
            <div class="card">
                <div class="card-body">
                    <div>
                        <h4 class="cardTitle mb-1 mt-1">
                            {{ (overViewData.sumCorpSubsidy) ? $func.formatPrice(overViewData.sumCorpSubsidy) : 0}}
                        </h4>
                        <p class="text-muted mb-0">Credit Meal Plan</p>
                    </div>
                    <div class="row">
                        <p class="text-muted mt-3 mb-0 col-7 cardSubtitle1">
                            <span>
                                {{ (overViewData.totalMealOrders) ? $func.formatCommaSepNum(overViewData.totalMealOrders) : 0 }}
                            </span> 
                            Quantity
                        </p>
                        <span class="align-center col-5 cardSubtitle2">
                            <p>
                                {{ (overViewData.mealPlanAvgSale) ? $func.formatPrice(overViewData.mealPlanAvgSale) : 0 }}
                            </p>
                            AOV
                        </span>
                    </div>
                </div>
            </div>
        </div>

        <div class="col-md-6 col-xl-3 overviewCard">
            <div class="card">
                <div class="card-body">
                    <div>
                        <h4 class="cardTitle mb-1 mt-1">
                            {{ (overViewData.sumWallet) ? $func.formatPrice(overViewData.sumWallet) : 0 }}
                        </h4>
                        <p class="text-muted mb-0">Wallet</p>
                    </div>
                    <div class="row">
                        <p class="text-muted mt-3 mb-0 col-7 cardSubtitle1">
                            <span>
                                {{( overViewData.totalWalletOrders) ? $func.formatCommaSepNum(overViewData.totalWalletOrders) : 0 }}
                            </span>
                            Orders
                        </p>
                        <span class="align-center col-5 cardSubtitle2">
                            <p>
                                {{ (overViewData.walletAvgSale) ? $func.formatPrice(overViewData.walletAvgSale) : 0 }}
                            </p>
                            AOV
                        </span>
                    </div>
                </div>
            </div>
        </div>

        <div class="col-md-6 col-xl-3 overviewCard">
            <div class="card">
                <div class="card-body">
                    <div>
                        <h4 class="cardTitle mb-1 mt-1">
                            {{ (overViewData.sumVoucher) ? $func.formatPrice(overViewData.sumVoucher) : 0 }}
                        </h4>
                        <p class="text-muted mb-0">Voucher</p>
                    </div>
                    <div class="row">
                        <p class="text-muted mt-3 mb-0 col-7 cardSubtitle1">
                            <span>
                                {{ (overViewData.totalVouchersOrders) ? $func.formatCommaSepNum(overViewData.totalVouchersOrders) : 0 }}
                            </span>
                            Orders
                        </p>
                        <span class="align-center col-5 cardSubtitle2">
                            <p>
                                {{ (overViewData.voucherAvgSale) ? $func.formatPrice(overViewData.voucherAvgSale) : 0 }}
                            </p>
                            AOV
                        </span>
                    </div>
                </div>
            </div>
        </div>

        <div class="col-md-6 col-xl-3 overviewCard">
            <div class="card">
                <div class="card-body">
                    <div>
                        <h4 class="cardTitle mb-1 mt-1">
                            {{ (overViewData.sumCoupon) ? $func.formatPrice(overViewData.sumCoupon) : 0 }}
                        </h4>
                        <p class="text-muted mb-0">Coupon</p>
                    </div>
                    <div class="row">
                        <p class="text-muted mt-3 mb-0 col-7 cardSubtitle1">
                            <span>
                                {{ (overViewData.totalCouponsOrders) ? $func.formatCommaSepNum(overViewData.totalCouponsOrders) : 0 }}
                            </span>
                            Orders
                        </p>
                        <span class="align-center col-5 cardSubtitle2">
                            <p>
                                {{ (overViewData.couponAvgSale) ? $func.formatPrice(overViewData.couponAvgSale) : 0 }}
                            </p>
                            AOV
                        </span>
                    </div>
                </div>
            </div>
        </div>

        <div class="col-md-6 col-xl-3 overviewCard">
            <div class="card">
                <div class="card-body">
                    <div>
                        <h4 class="cardTitle mb-1 mt-1">
                            {{ (overViewData.sumTaxes) ? $func.formatPrice(overViewData.sumTaxes) : 0 }}
                        </h4>
                        <p class="text-muted mb-0">Taxes for Ecom</p>
                    </div>
                    <div class="row">
                        <p class="text-muted mt-3 mb-0 col-7 cardSubtitle1">
                            <span>
                                {{ (overViewData.taxOrders) ? $func.formatCommaSepNum(overViewData.taxOrders) : 0 }}
                            </span>
                            Orders
                        </p>
                        <span class="align-center col-5 cardSubtitle2">
                            <p>
                                {{ (overViewData.avgTaxes) ? $func.formatPrice(overViewData.avgTaxes) : 0 }}
                            </p>
                            AOV
                        </span>
                    </div>
                </div>
            </div>
        </div>

        <div class="col-md-6 col-xl-3 overviewCard">
            <div class="card">
                <div class="card-body">
                    <div>
                        <h4 class="cardTitle mb-1 mt-1">
                            {{ (overViewData.sumRejectedGrandTotal) ? $func.formatPrice(overViewData.sumRejectedGrandTotal) : 0 }}
                        </h4>
                        <p class="text-muted mb-0">Rejected Orders</p>
                    </div>
                    <div class="row">
                        <p class="text-muted mt-3 mb-0 col-7 cardSubtitle1">
                            <span>
                                {{ (overViewData.totalRejectedOrders ) ? $func.formatCommaSepNum(overViewData.totalRejectedOrders) : 0 }}
                            </span>
                            Orders
                        </p>
                        <span class="align-center col-5 cardSubtitle2">
                            <p>{{(overViewData.rejectedAvgSale) ? $func.formatPrice(overViewData.rejectedAvgSale) : 0}}</p> AOV
                        </span>
                    </div>
                </div>
            </div>
        </div>

        <div class="col-md-6 col-xl-3 overviewCard">
            <div class="card">
                <div class="card-body">
                    <div>
                        <h4 class="cardTitle mb-1 mt-1">
                            {{ (dashboardData.totalEmployees) ? $func.formatCommaSepNum(dashboardData.totalEmployees) : 0 }}
                        </h4>
                        <p class="text-muted mb-0">Total Users</p>
                    </div>
                    <div class="row">
                        <p class="text-muted mt-3 mb-0 col-7 cardSubtitle1">
                            <span>
                                {{ (dashboardData.totalCorporate) ? $func.formatCommaSepNum(dashboardData.totalCorporate) : 0 }}
                            </span>
                            Corporate Users
                        </p>
                        <!-- <span class="align-center col-5 cardSubtitle2">
                            <p>{{overViewData.totalActiveOnboardedUsers - overViewData.totalActiveCorporateUsers}}</p> Other 
                        </span> -->
                    </div>
                </div>
            </div>
        </div>

        <!-- <div class="col-md-2 cardBody">
            <div class="card">
                <div class="card-body">
                    <div>
                        <h4 class="mb-1 mt-1">
                        {{dashboardData.totalCorporate}}
                        </h4>
                        <p class="text-muted mb-0">Total Corporates</p>
                    </div>
                
                </div>
            </div>
        </div> -->

        <!-- <div class="col-md-2 cardBody">
            <div class="card">
                <div class="card-body">
                    <div>
                        <h4 class="mb-1 mt-1">
                        {{dashboardData.totalOrders}}
                        </h4>
                        <p class="text-muted mb-0">Total Orders</p>
                    </div>
                
                </div>
            </div>
        </div> -->

        <!-- <div class="col-md-2 cardBody">
            <div class="card">
                <div class="card-body">
                    <div>
                        <h4 class="mb-1 mt-1">
                        {{dashboardData.totalEmployees}}
                        </h4>
                        <p class="text-muted mb-0">Total Users</p>
                    </div>
                
                </div>
            </div>
        </div> -->

        <!-- <div class="col-md-2 cardBody">
            <div class="card">
                <div class="card-body">
                    <div>
                        <h4 class="mb-1 mt-1">
                        {{dashboardData.totalSales}}
                        </h4>
                        <p class="text-muted mb-0">Total Sales</p>
                    </div>
                
                </div>
            </div>
        </div> -->

        <!-- <div class="col-md-2 cardBody">
              <div class="card">
                  <div class="card-body">
                      <div>
                          <h4 class="mb-1 mt-1">
                            {{dashboardData.totalEmployees}}
                          </h4>
                          <p class="text-muted mb-0">Meal Plan Orders</p>
                      </div>
                    
                  </div>
              </div>
          </div> -->

    </div>
    <!-- <div class="row" style="margin-top: 44px;">
    <div class="col-md-6 col-xl-3">
        <div class="card">
            <div class="card-body">
                <div class="float-end mt-2">
                    <apexchart class="apex-charts" dir="ltr" width="70" height="40" :options="chartOptions" :series="series"></apexchart>
                </div>
                <div>
                    <h4 class="mb-1 mt-1">
                       
                        <span data-plugin="counterup">
                            <countTo :startVal="1000" :endVal="dashboardData.totalCorporate" :duration="2000"></countTo>
                        </span>
                    </h4>
                    <p class="text-muted mb-0">Total Corporates</p>
                </div>
                <p class="text-muted mt-3 mb-0">
                    <span v-bind:class="percentColorClass(dashboardData.lastWeekCorpPercent)">
                        <i v-bind:class="arrowClass(dashboardData.lastWeekCorpPercent)"></i>{{dashboardData.lastWeekCorpPercent}}%
                    </span>
                    since last week
                </p>
            </div>
        </div>
    </div>
    <div class="col-md-6 col-xl-3">
        <div class="card">
            <div class="card-body">
                <div class="float-end mt-2">
                    <apexchart class="apex-charts" type="radialBar" dir="ltr" width="45" height="45" :options="orderRadial" :series="orderseries"></apexchart>
                </div>
                <div>
                    <h4 class="mb-1 mt-1">
                        <span data-plugin="counterup">
                            <countTo :startVal="100" :endVal="dashboardData.totalOrders" :duration="2000"></countTo>
                        </span>
                    </h4>
                    <p class="text-muted mb-0">Total Orders</p>
                </div>
                <p class="text-muted mt-3 mb-0">
                    <span v-bind:class="percentColorClass(dashboardData.lastWeekOrdersPercent)">
                        <i v-bind:class="arrowClass(dashboardData.lastWeekOrdersPercent)"></i>{{dashboardData.lastWeekOrdersPercent}}%
                    </span>
                    since last week
                </p>
            </div>
        </div>
    </div>
    

    <div class="col-md-6 col-xl-3">
        <div class="card">
            <div class="card-body">
                <div class="float-end mt-2">
                    <apexchart class="apex-charts" type="radialBar" dir="ltr" width="45" height="45" :options="customerRadial" :series="customerseries"></apexchart>
                </div>
                <div>
                    <h4 class="mb-1 mt-1">
                        <span data-plugin="counterup">
                            <countTo :startVal="1000" :endVal="dashboardData.totalEmployees" :duration="2000"></countTo>
                        </span>
                    </h4>
                    <p class="text-muted mb-0">Total Employees</p>
                </div>
                <p class="text-muted mt-3 mb-0">
                    <span v-bind:class="percentColorClass(dashboardData.lastWeekEmpPercent)">
                        <i v-bind:class="arrowClass(dashboardData.lastWeekEmpPercent)"></i>{{dashboardData.lastWeekEmpPercent}}%
                    </span>
                    since last week
                </p>
            </div>
        </div>
    </div> -->

    <!-- end col-->
<!-- </div> -->
   <!-- Stats end -->
   
    <div class="row">
          <div class="col-md-12">
            <div class="card">
                 <div class="card-body">
                   
                     <div class="row">
                        <div class="col-md-6">
                            <h6>Sales Graph</h6>
                        </div>
                         <div class="col-md-4">
                            
                                    <label class="form-label" for="formrow-endTime-input">Select Corporate Branch</label>
                                    <multiselect v-model="corpBranch"  :options="corpArr" track-by="corpBranchID" 
                                    label="corpBranchName" :show-labels="false" @input="changeCorpBranch"
                                    ></multiselect>
                            
                        </div>
                     </div>
                     <!-- {{chartOptionsCorpStats}} -->
                     <!-- <apexchart
                            class="apex-charts"
                            height="350"
                            type="area"
                            dir="ltr"
                            :series="corporateStats"
                            :options="chartOptionsCorpStats"
                            ></apexchart> -->
                    <apexchart v-if="salesGraph.salesGraph" type="area" class="apex-charts" dir="ltr" height="339" :options="salesGraph.salesGraph.chartOptions" :series="salesGraph.salesGraph.series"></apexchart>
                 </div>
            </div>
        </div>
        

    </div>
    <div class="row">
        <div class="col-md-6">
            <div class="card">
                 <div class="card-body" style="min-height: 238px;">
                     <h6> Order Statistics
                             <!-- <router-link :to="{ name: 'foodcourtStatisticsTable', params: { id: this.corpBranchID } }">
                             <i class="mdi mdi-arrow-right" style="float: right;font-size: 21px;"></i>  
                            </router-link> -->
                     </h6>
                     <div v-if="!foodCourtSeries.length == 0">
                        <apexchart class="apex-charts" height="320" type="pie" dir="ltr" :series="dashboardData.foodCourtArr.series" :options="dashboardData.foodCourtArr.chartOptions"></apexchart>
                     </div>
                     <div v-else style="height: 320px;"></div>
                 </div>
            </div>

           
        </div>
        <div class="col-md-6">
      <div class="card">
      <div class="card-body">

          <h6> Principal Branch Users </h6>
          <!-- Bar chart -->
            <!-- <apexchart type="line" class="apex-charts" dir="ltr" height="339" :options="salesGraph.topBuyingCustomers.chartOptions" :series="salesGraph.topBuyingCustomers.series"></apexchart> -->
             <apexchart
             v-if="userGraph"
                  class="apex-charts"
                  height="350"
                  type="bar"
                  dir="ltr"
                  :series="userGraph.series"
                  :options="userGraph.chartOptions"
              ></apexchart>
      </div>
      </div>
    </div>
        
    </div>

 <div class="row">
    
</div>

  </Layout>
</template>
<style scoped>
.cardBody{
    min-width: 180px!important;
    width: fit-content!important;
}

.cardSubtitle1 span {
    color: #a4827d;
    font-weight: 500;
}

.cardSubtitle2 p {
    color: #f3766a;
    font-weight: 500;
    margin: 0;
}
</style>


 